@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin-bottom: -8px;
}

.pageContainer {
  background-color: white;
}

#virtualListContainer {
  overflow: visible !important;
}

#virtualListBody {
  overflow: hidden;
  height: 100vh !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(199, 199, 199, 0.84);
  border-radius: 3px;
}

#document-viewer::-webkit-scrollbar {
  width: 14px;
  background-color: white;
  transition: all 0.5s ease-in-out;
}

#document-viewer::-webkit-scrollbar:hover {
  background-color: rgb(147 197 253);
  border: 1px solid rgb(59 130 246);
}

#document-viewer::-webkit-scrollbar-thumb:hover {
  background-color: rgb(59 130 246);
}
#document-viewer::-webkit-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgb(59, 246, 153);
}

.custom-invisible-scroll::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
}

.custom-invisible-scroll::-webkit-scrollbar-thumb {
  scrollbar-width: none;
  display: none;
}

.custom-view-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: white;
}

.custom-view-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(199, 199, 199, 0.84);
  border-radius: 0;
}

.revisiondiff > del {
  color: #a90d0d !important;
}

.filepond--root {
  max-height: 50vh;
}

.mentions__control,
.task_mentions__control {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-radius: 0.125rem;
  resize: none;
  width: 100%;
}

.mentions__input,
.task_mentions__input {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.125rem;
  resize: none;
  width: 100%;
}

.mentions__suggestions__list,
.task_mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  max-height: 10rem;
  overflow: auto;
}

.mentions__suggestions__item,
.task_mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused,
.task_mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention,
.task_mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow:
    1px 1px 1px white,
    1px -1px 1px white,
    -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}

.task_mentions__suggestions {
  margin-top: 0 !important;
  max-width: 17.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
